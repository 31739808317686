import React,{useEffect,useContext} from 'react';
import './App.css';
import Layout from './GC/Layout/Layout';
import PrivacyPolicy from './GC/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from './GC/TermsAndCondition/TermsAndCondition';
import FbAuth from './GC/FbAuth/FbAuth';
import FbRedirect from './GC/FbRedirect/FbRedirect';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { GlobalContext } from './Context/Context';

function App() {
  const { tokenContext } = useContext(GlobalContext);
  const [tokenState,setTokenState] = tokenContext;
  const defaulUrl = '/shoppingassistant';
  useEffect(()=>{
    let i = 0;
    const cookieFindoutInterval = setInterval(() => {
      i++;
      const cookieObj = document.cookie;
      console.log(cookieObj);
      if(cookieObj) {

        const cookie = JSON.parse(cookieObj);
        const owner = cookie.owner;
        if(typeof owner === 'string' && owner === 'sharpchotu'){
          const token= cookie.token;
          setTokenState(token);
        }
        clearInterval(cookieFindoutInterval);
        
      }

      if(i===5) clearInterval(cookieFindoutInterval);

      i++;
    }, 5000);
  },[])
  return (
    <>
      <Router>
        <Layout>
          <Switch>
            <Route path={defaulUrl+"/"} exact component={PrivacyPolicy} />
            <Route path={defaulUrl + "/privacy"} exact component={PrivacyPolicy} />
            <Route path={defaulUrl + "/terms"} exact component={TermsAndCondition} />
            <Route path={defaulUrl + "/fbauth"} exact component={FbAuth} />
            <Route path={defaulUrl + "/fbsuccess"} exact component={FbRedirect} />  
          </Switch>
        </Layout>
      </Router>
    </>
  );
}

export default App;
