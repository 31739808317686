import React, {useEffect} from 'react';
import { ASSETS_URL } from '../../Utility/AllMagicStrings';

const FbAuth = () =>{
    useEffect(()=>{
        window.open(`https://www.facebook.com/v10.0/dialog/oauth?client_id=2757244651198407&redirect_uri=https://sharpchotu-server.com/shoppingassistant/fbsuccess&response_type=token`, '_self');
    },[])
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <img style={{ height: 200, width: 200 }} src={ASSETS_URL + "chotu.svg"} />
            <div style={{ fontSize: 22 }}>Please wait....</div>
        </div>
    )
}

export default FbAuth;