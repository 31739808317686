import React from 'react';
import styles from './Header.module.css';
import {Link} from 'react-router-dom';
import {ASSETS_URL} from '../../Utility/AllMagicStrings';
const Header = () =>{
    const defaultUrl = "/shoppingassistant";
    const inlineStyles = {
        linkStyle:{
            textDecoration:'none',
            color:'inherit'
        }
    }
    return(
        <>
            <header className={styles.header}>
                <img className={styles.chotu_logo} src={ASSETS_URL+"header.svg"}/>
                <div className={styles.terms_policy_container}>
                    <div>
                        <Link style={inlineStyles.linkStyle} to={defaultUrl+"/terms"}>{"Terms & conditions"}</Link>
                    </div>
                    <div>
                        <Link style={inlineStyles.linkStyle} to={defaultUrl + "/privacy"}>{"Privacy policy"}</Link>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;