import React from 'react';
import styles from './Layout.module.css';
import Header from '../Header/Header';
import Sidebar from '../Sidebar/Sidebar';
const Layout = (props) =>{

    return(
        <>
            <Header />
            {/* <Sidebar /> */}
            <main className={styles.main}>
                {props.children}
            </main>

            <footer>

            </footer>
        </>
    )
}

export default Layout;