import React from 'react';
import styles from './TermsAndCondition.module.css';
import { Link } from 'react-router-dom';

const TermsAndCondition = () => {

    return (
        <>
            <div className={styles.terms_container}>
                <h1 style={{ textAlign: 'center' }}>{"Terms of Service"}</h1>
                <p>
                    Sharp-Chotu ("we", "us" or "our"), a company provides a proprietary tool, available through its website ("Site"), browser extension("Browser App") and mobile application ("App") that allows users to save and track third-party products that they are interested in purchasing ("Products"). These Terms of Service ("Terms") govern your access and use of the Site, App, and Services. Our Privacy Notice, available at <Link to="/shoppingassistant/privacy">https://www.sharpchotu-server.com/shoppingassistant/privacy</Link> ("Privacy Notice") governs our collection, processing and transfer of any Personal Data (as defined in the Privacy Notice). "User" or "you" means an individual using the Services.
                </p>
                <p>
                    Please read these Terms carefully. By visiting our Site, yo agree to be bound by these Terms relating to the use of the Site. We may amend the Terms from time to time and when you continue to use the Site after such an update, we will consider this your acceptance of any such amendment or update. When you register through the Site or App, you signify your assent to these Terms as they relate to the use of the Services. Changes may be made to these Terms from time to time and we will make commercially reasonable efforts to contact you in respect of any material changes. If you do not agree to any of these Terms, please do not use the Site and/or register to use the Services.
                </p>
                <h2>Table of Contents</h2>
                <ol className={styles.table_content}>
                    <li><a href="#description-of-services">Description of the Services</a></li>
                    <li><a href="#use-of-services">Use of the Services</a></li>
                    <li><a href="#accounts">Accounts</a></li>
                    <li><a href="#termination-of-account">Termination of Account</a></li>
                    {/* <li><a href="#">Cashback Program</a></li>
                    <li><a href="#">Premium Cashback Program</a></li>
                    <li><a href="#">Cancellation Policy</a></li> */}
                    <li><a href="#content">Content</a></li>
                    <li><a href="#use-restrictions">Use Restrictions</a></li>
                    <li><a href="#intellectual-property">Intellectual Property</a></li>
                    <li><a href="#disclaimer-of-warranty">Disclaimers and Disclaimer of Warranty</a></li>
                    <li><a href="#limitation-of-liability">Limitation of Liability</a></li>
                    <li><a href="#indemnification">Indemnification</a></li>
                    <li><a href="#third-parties">Third Parties</a></li>
                    <li><a href="#miscellaneous">Miscellaneous</a></li>
                </ol>
                <ol className={styles.content_header}>
                    <li id="description-of-services">
                        Description of the Services.
                        <ol className={[styles.content_header,styles.content_details].join(" ")}>
                            <li>
                                Through your Sharp-Chotu account you will be able to save and track Products. The dashboard in your account will allow you to see all Products that you have saved, and you will receive notification through the Services when prices for Products you have saved have a reduction in price, return in stock or are low in stock, per your request for each Product.
                            </li>
                            <li>
                                You can save Products to your account either by manually adding a link to the Product from within your account (using the "+" button) or directly from the Product website by using Sharp-Chotu Google Chrome extension.
                            </li>
                            <li>
                                As part of the Services, when you purchase Products you have saved, once you open the checkout page, Sharp-Chotu will automatically scan for coupons that may be relevant to your purchase.
                            </li>
                        </ol>
                    </li>
                    <br/>
                    <li id="use-of-services">
                        Use of the Services.
                        <ol className={[styles.content_header, styles.content_details].join(" ")}>
                            <li>
                                Sharp-Chotu allows you to use the Site, App, and Services on a non-exclusive basis subject to these Terms. Sharp-Chotu may choose to change or discontinue any part of the Services at any time, without notifying you of that decision and shall not be liable to you or any third party if it chooses to change or discontinue the Services.
                            </li>
                            <li>
                                Currently, the Services are available free-of-charge. However Sharp-Chotu Services at any future date may be charged and before doing so, you will be notified prior to such change and given the opportunity to choose whether to continue using the paid Services or to discontinue your use of our Services.
                            </li>
                            <li>
                                Use of and access to the Site, App, and Services is void where prohibited by law. The Site, App, and Services are intended for adults. You represent and warrant that (a) any and all registration information you submit is truthful and accurate; (b) you will maintain the accuracy of such information; (c) you are 18 years of age or older, or if a minor, you have obtained your legal guardian's permission to enter these Terms, and you have the capacity to enter a binding contract; (d) your use of the Site, App, and Services does not violate any applicable law, regulation, or obligation you may have to a third party; and (e) you shall comply with applicable laws, regulations, guidelines, and these Terms throughout your use of the Site, App, and/or Services.
                            </li>
                            <li>
                                By using the Services, you acknowledge that Sharp-Chotu does not own, rent, lease, sell, resell, furnish, provide, manage, or control any of the Products. Sharp-Chotu                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                     only provides a tool that allows you to save and track Products. It shall not be responsible for any purchases you make, the quality or provision of any Products, or the conduct or behavior of any retailers whose Products you have saved, tracked, or purchased.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li id="accounts">
                        Accounts.
                        <ol className={[styles.content_header, styles.content_details].join(" ")}>
                            <li>
                                In order to use the Services, you will need to have a registered account. You can register by logging in through your Facebook, Gmail, or other third-party login account ("Login Account") as may be permitted by Sharp-Chotu from time to time. By registering through a Login Account, you represent and warrant that such Login Account is yours and that you have full rights to provide us with the information in such Login Account. To complete the registration process, you must provide all registration information requested by us. We may indicate that some information is optional, but if you agree to provide such information, it may assist us in providing you with improved Services.
                            </li>
                            <li>
                                Sharp-Chotu may refuse to allow any user to open an account for any reason at its sole discretion and in compliance with applicable law and may change the method of registration at its sole discretion.
                            </li>
                            <li>
                                You agree to notify us immediately of any unauthorized use of your account or password. You are fully and solely responsible for the security of your computer system and/or mobile device and all activity on your account, even if such activities were not committed by you. To the fullest extent permitted by law, Sharp-Chotu will not be liable for any losses or damage arising from unauthorized use of your account or password and you agree to indemnify and hold Sharp-Chotu harmless for any unauthorized, improper or illegal use of your account and any charges and taxes incurred, unless you have notified us via e-mail at <strong>chotu.sharp@gmail.com</strong> that your account has been compromised and have requested that we block access to it, which we will do as soon as reasonably practicable. We do not police for and cannot guarantee that we will learn of or prevent any inappropriate use of the Services.
                            </li>
                            
                        </ol>
                    </li>
                    <br />
                    <li id="termination-of-account">
                        Termination of Account.
                        <ol className={[styles.content_header, styles.content_details].join(" ")}>
                            <li>
                                You may request termination of your account at any time and for any reason by sending an email to chotu.sharp@gmail.com Following such request, we shall close your account as soon as reasonably practicable.
                            </li>
                            <li>
                                You agree that Sharp-Chotu may for any reason, at its sole discretion, terminate your account and may remove any Content (as defined below) associated with your account and take any other corrective action it deems appropriate and will notify you of such termination or corrective action via email or by posting on this Site. Grounds for such termination or suspension may include (i) extended periods of inactivity, (ii) violation of the letter or spirit of these Terms, (iii) fraudulent, harassing or abusive behavior, or (iv) behavior that is illegal or harmful to other users, third parties, or our business interests. If your account is terminated, you may not rejoin Sharp-Chotu again without permission from Sharp-Chotu. Upon termination of your account, you shall not have any further access to any Content that may be available through your account.
                            </li>
                            <li>
                                Any suspension or termination of your account shall not affect your obligations to us under these Terms (including but not limited to ownership, indemnification, any representations and warranties made by you, and limitation of liability), which by their sense and context are intended to survive such suspension or termination.
                            </li>
                            <li>
                                We reserve the right to investigate suspected violations of these Terms or illegal or inappropriate behavior through the Services. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity, behavior, or Content of anyone believed to have violated these Terms or to have engaged in illegal behavior in connection with the Services.
                            </li>
                        </ol>
                    </li>
                    <br />
                    <li id="content">
                        Content.
                        <ol className={[styles.content_header, styles.content_details].join(" ")}>
                            <li>
                                Certain types of content may be made available through the Services. "Content" as used in these Terms means, collectively, all content on or made available through the Services, including any images, photos, pictures, links, pricing information, coupons, recommendations, and any modifications or derivatives of the foregoing.
                            </li>
                            <li>
                                Content comes from a variety of sources. You understand that Sharp-Chotu is not responsible for the accuracy, usefulness, safety, appropriateness of, or infringement of any intellectual property rights of or relating to this Content. Sharp-Chotu DOES NOT ENDORSE ANY CONTENT OR ANY OPINION, RECOMMENDATION, OR ADVICE EXPRESSED IN ANY CONTENT AND EXPRESSLY DISCLAIMS ANY AND ALL LIABILITY IN CONNECTION WITH THE CONTENT. Sharp-Chotu DISCLAIMS ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF ANY AND ALL USERS (INCLUDING UNAUTHORIZED USERS), WHETHER SUCH ACTS OR OMISSIONS OCCUR DURING THE USE OF THE SERVICE OR OTHERWISE.
                            </li>
                            <li>
                                You hereby waive any legal or equitable rights or remedies you have or may have against us with respect to any Content.
                            </li>
                            
                        </ol>
                    </li>
                    <br/>
                    <li id="use-restrictions">
                        Use Restrictions :
                        <span>
                            &nbsp;You may not do or attempt to do or facilitate a third party in doing any of the following: (1) decipher, decompile, disassemble, or reverse-engineer any software and/or code used to provide the Site, App, or Services, including framing or mirroring any part of the Site, App, or Services; (2) circumvent, disable, or otherwise interfere with security-related features of the Services or features that prevent or restrict use or copying of any Content; (3) use the Site, App, or Services or Content thereon in connection with any commercial endeavors in any manner, except for the purposes specifically set forth in these Terms; (4) use any robot, spider, site search or retrieval application, or any other manual or automatic device or process to retrieve, index, data-mine, or in any way reproduce or circumvent the navigational structure or presentation of the Site, App, or Services; (5) use or access another user’s account or password without permission; or (6) use the Site, App, or Services or content thereon in any manner not permitted by these Terms.
                        </span>
                    </li>
                    <br />
                    <li id="intellectual-property">
                        Intellectual Property :
                        <span>
                            &nbsp;Sharp-Chotu or its licensors, as the case may be, have all right, title, and interest in the Site, App, and Services, including any material or Content made available thereon, including its overall appearance, text, graphics, graphics design, videos, demos, interfaces, and underlying source files and all worldwide intellectual property rights, the trademarks, service marks, and logos contained therein, whether registered or unregistered. Except as expressly permitted herein, you may not copy, further develop, reproduce, republish, modify, alter download, post, broadcast, transmit or otherwise use any Content of the Site, App, or Services for any purpose. You will not remove, alter or conceal any copyright, trademark, service mark or other proprietary rights notices incorporated in the Services. All trademarks are trademarks or registered trademarks of their respective owners. Nothing in these Terms should be construed as granting you any right to use any trademark, service mark, logo, or trade name of Sharp-Chotu or any third party. If you provide Sharp-Chotu with comments or suggestions for the modification, correction, improvement or enhancement of Services then you agree that no such feedback shall be subject to any obligation of confidentiality, and we may use or publish such feedback at our discretion.
                        </span>
                    </li>

                    <br />
                    <li id="disclaimer-of-warranty">
                        Disclaimers and Disclaimer of Warranty.
                        <ol className={[styles.content_header, styles.content_details].join(" ")}>
                            <li>
                                All information and Content available through the Services is for information purposes only and Sharp-Chotu provides no guarantees with respect thereto. Your use of any aspect of the Site, App, or Services is at your sole discretion and risk. Sharp-Chotu cannot and does not accept any liability in respect of any activities that you may undertake through using the Services, including by means of linking to third-party websites and/or any purchases made on those third-party websites. The Site, App, Services, and any Content available thereon, are provided on an AS IS and AS AVAILABLE basis without warranties of any kind.
                            </li>
                            <li>
                                Sharp-Chotu EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED, OR STATUTORY, RELATING TO THE SITE AND/OR SERVICES OR ANY CONTENT THEREON, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OF PROPRIETARY RIGHTS, COURSE OF DEALING OR COURSE OF PERFORMANCE. WE DISCLAIM ANY WARRANTIES, EXPRESS OR IMPLIED, (I) REGARDING THE SECURITY, ACCURACY, RELIABILITY, TIMELINESS AND PERFORMANCE OF THE SITE, APP, AND/OR SERVICES; OR (II) THAT THE SITE, APP OR SERVICES WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY, CURRENCY, COMPLETENESS OR USEFULNESS OF ANY INFORMATION PROVIDED ON THE SITE, APP AND/OR SERVICES. Sharp-Chotu DOES NOT WARRANT THAT ANY CONTENT AND/OR DESCRIPTION PROVIDED THROUGH THE SERVICES REGARDING PRODUCTS OR OTHERWISE IS ACCURATE, COMPLETE, RELIABLE, CURRENT, OR ERROR-FREE.
                            </li>
                            <li>
                                No advice or information, whether oral or written, obtained by you from Sharp-Chotu shall create any warranty not expressly stated in these Terms. If you choose to rely on such information, you do so solely at your own risk. Some states or jurisdictions do not allow the exclusion of certain warranties. Accordingly, some of the above exclusions may not apply to you. Check your local laws for any restrictions or limitations regarding the exclusion of implied warranties.
                            </li>

                        </ol>
                    </li>
                    <br />
                    <li id="limitation-of-liability">
                        Limitation of Liability.
                        <ol className={[styles.content_header, styles.content_details].join(" ")}>
                            <li>
                                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Sharp-Chotu, ITS OFFICERS, DIRECTORS, EMPLOYEES, ASSIGNEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DAMAGES WHATSOEVER, INCLUDING BUT NOT LIMITED TO GENERAL, INCIDENTAL, CONSEQUENTIAL, INDIRECT, DIRECT, SPECIAL OR PUNITIVE DAMAGES, ARISING OUT OF OR RELATING TO YOUR USE OF THE SERVICES, INCLUDING BUT NOT LIMITED TO THE QUALITY, ACCURACY, OR UTILITY OF THE INFORMATION PROVIDED AS PART OF OR THROUGH THE SERVICES, WHETHER THE DAMAGES ARE FORESEEABLE AND WHETHER OR NOT Sharp-Chotu HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY CASE, OUR ENTIRE LIABILITY UNDER ANY PROVISION OF THESE TERMS SHALL NOT EXCEED AN AMOUNT OF $100.
                            </li>
                            <li>
                                In addition to the foregoing, Sharp-Chotu assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission or communications line failure, theft or destruction or unauthorized access to, or alteration of, any Content or Services. Sharp-Chotu is not responsible for any problems or technical malfunction of any telephone network or lines, computer online systems or equipment, servers or providers, computer equipment, software, failure of any email due to technical problems or traffic congestion on the Internet or on the Services, including any injury or damage to users or to any person's mobile device or computer related to or resulting from use of the Services. Under no circumstances shall Sharp-Chotu be responsible for any loss or damage, including personal injury or death, resulting from use of the Services, whether online or offline. In addition, we assume no responsibility for any incorrect data, including Personal Data provided by you and you hereby represent and warrant that you are solely responsible for any and all data provided to Sharp-Chotu and you shall assume any and all liability for any consequences of provision of such data to us.
                                Please note that we are not affiliated with third-party online stores, nor do we endorse anything provided on them, including the Products. While we make reasonable attempts to notify you of changes in prices with respect to the particular version of the Product that you tagged, we do not warrant that the prices that are included in our notification will be the actual price of the relevant Product and/or that the relevant Product will actually be available for sale. We only provide you with notifications and/or related information and are not responsible for the actions of such third-party online store and/or that such online store will respect any agreements entered and/or purchases made. We do not warrant that any Product is safe, legal or appropriate. We expressly disclaim any and all liability in connection with the above.
                            </li>
                            <li>
                                We work with certain third-party online stores through affiliate marketing programs. This means that with respect to (some of your) interactions on the Site or App, such as saving items, clicking on links to third-party websites, clicking on advertising links, or purchasing an item, Sharp-Chotu may earn commissions from its business partners.
                            </li>

                        </ol>
                    </li>
                    <br />
                    <li id="indemnification">
                        Indemnification :
                        <span>
                            &nbsp;You agree to indemnify, defend, and hold harmless Sharp-Chotu, its employees, directors, officers, subcontractors and agents against any and all claims, damages, losses, liabilities, costs or expenses (including attorneys’ fees and court costs) that arise directly or indirectly from: (a) breach of these Terms by you or anyone using your computer, mobile device, Login Account, password or login information, whether authorized or unauthorized; (b) any claim, loss or damage experienced from your use or attempted use of (or inability to use) the Site, App, or Services; (c) your violation of any law or regulation or any of your obligations, representations, or warranties hereunder including but not limited to breach of any privacy and/or data protection laws and regulations to which you are subject; (d) your infringement of any right of any third party, including without limitation the infringement of any third party intellectual property right, moral or publicity right; or (e) any other matter for which you are responsible hereunder or under law.
                        </span>
                    </li>
                    <br/>
                    <li id="third-parties">
                        Third Parties :
                        <span>
                            &nbsp;The Site and App may provide you with links to third-party websites, applications, and services. We make no promises regarding any content, goods or services provided by such third parties and all use of third-party websites and applications is at your own risk. Additionally, we do not accept responsibility for any payments processed or submitted through third-party websites and applications or for the privacy practices of such third parties. We do not endorse any products offered by third parties and we urge you to exercise caution when using third-party websites or applications.
                        </span>
                    </li>
                    <br />
                    <li id="miscellaneous">
                        Miscellaneous :
                        <span>
                            &nbsp;Nothing in these Terms creates any agency, employment, joint venture, or partnership relationship between you and Sharp-Chotu or enables you to act on behalf of Sharp-Chotu. Except as expressly stated herein, these Terms constitute the entire agreement between us and you pertaining to the subject matter hereof, and any other agreements existing between us relating thereto are hereby canceled. We may assign and/or transfer our rights and obligations hereunder to any third party and will notify you of such assignment or transfer, including by updating these Terms. You may not assign and/or transfer any of your rights or obligations hereunder and any assignment in violation of the foregoing shall be void. No waiver of any breach or default hereunder shall be deemed to be a waiver of any other breach or default. If we are required to provide notice to you hereunder, we may provide such notice to the contact details you provided upon registration.
                        </span>
                    </li>
                </ol>
            </div>
        </>
    )
}

export default TermsAndCondition;
