import React, { useState, createContext, useEffect } from 'react';
import * as pages from '../CommonFiles/PageNames';

// import axios from 'axios';

export const GlobalContext = createContext();

export const GlobalContextProvider = (props) => {

    const [tokenState, setTokenState] = useState(null);
    const [selectedPage, setSelectedPage] = useState(null);
    const [history, setHistory] = useState([]);
    const [chotuMessage, setChotuMessage] = useState({
        type: null,
        message: '',
        color: ''
    });

    const [selectedFilterList, setSelectedFilterList] = useState('all');
    const [selectedFilterStore, setSelectedFilterStore] = useState('all');
    const [filterStores, setFilterStores] = useState([]);
    const [lists, setLists] = useState([]);

    //const [tempProducts]
    const [products, setProducts] = useState({});
    const [selectedProductPage, setSelectedProductPage] = useState(0)
    const [productSortByOption, setProductSortByOption] = useState('date');

    const [favStores, setFavStores] = useState({});
    const [selectedFavStorePage, setSelectedFavStorePage] = useState(0);

    const [unfavStores, setUnfavStores] = useState({});
    const [selectedUnfavStorePage, setSelectedUnfavStorePage] = useState(0);

    const [storeSortByOption, setStoreSortByOption] = useState('name');

    const [favBrands, setFavBrands] = useState({});
    const [selectedFavBrandPage, setSelectedFavBrandPage] = useState(0);

    const [unfavBrands, setUnfavBrands] = useState({});
    const [selectedUnfavBrandPage, setSelectedUnfavBrandPage] = useState(0);

    const [brandSortByOption, setBrandSortByOption] = useState('name');

    const [storeOffers, setStoreOffers] = useState([]);

    const [notifications, setNotifications] = useState([]);

    const [productTrackingRules, setProductTrackingRules] = useState({});

    const [productsOrientation, setProductsOrientation] = useState('row')

    const [browserUrl, setBrowserUrl] = useState('');
    


    // useEffect(() => {
    //     if (selectedPage === pages.SIGN_IN) return;

    //     const isNavigateBack = history[history.length - 2] === selectedPage;
    //     if (isNavigateBack) {
    //         const updatedHistory = history;
    //         updatedHistory.pop();
    //         setHistory(updatedHistory);
    //     }
    //     else {
    //         const updatedHistory = history;
    //         updatedHistory.push(selectedPage);
    //         setHistory(updatedHistory);
    //     }
    // }, [selectedPage])

    //useEffect(() => {
    //console.log('from context internet connection',isInternetAvailable);
    // if(isInternetAvailable ){
    //     console.log(selectedPage);
    //     let prevSelectedPage = selectedPage;

    //     prevSelectedPage = pages.SIGN_IN;
    //     setSelectedPage(prevSelectedPage);
    //     return;

    //     setSelectedPage('');
    //     setTimeout(()=>{
    //         setSelectedPage(prevSelectedPage);
    //     },500);
    // }

    //unsubscribe();

    //}, [isInternetAvailable,selectedPage])


    useEffect(() => {
        if (!tokenState) {
            setHistory([]);
            // setChotuMessage({
            //     type: null,
            //     message: '',
            //     messageColor: ''
            // });
            setSelectedFilterList('all');
            setSelectedFilterStore('all');
            setFilterStores([]);
            setLists([]);

            setProducts({});
            setSelectedProductPage(0);
            setProductSortByOption('date');

            setFavStores({});
            setSelectedFavStorePage(0)

            setUnfavStores({})
            setSelectedUnfavStorePage(0)

            setStoreSortByOption('name')

            setFavBrands({})
            setSelectedFavBrandPage(0)

            setUnfavBrands({})
            setSelectedUnfavBrandPage(0)

            setBrandSortByOption('name')

            setStoreOffers([])

            setNotifications([])

            setProductTrackingRules({})


        }
    }, [tokenState])
    return (
        <GlobalContext.Provider value={{

            selectedPageContext: [selectedPage, setSelectedPage],
            chotuMessageContext: [chotuMessage, setChotuMessage],
            historyContext: [history, setHistory],
            tokenContext: [tokenState, setTokenState],
            storeOffersContext: [storeOffers, setStoreOffers],
            selectedFilterListContext: [selectedFilterList, setSelectedFilterList],

            selectedFilterStoreContext: [selectedFilterStore, setSelectedFilterStore],
            filterStoresContext: [filterStores, setFilterStores],
            listsContext: [lists, setLists],

            productsContext: [products, setProducts],
            selectedProductPageContext: [selectedProductPage, setSelectedProductPage],
            productSortByOptionContext: [productSortByOption, setProductSortByOption],

            favStoresContext: [favStores, setFavStores],
            selectedFavStorePageContext: [selectedFavStorePage, setSelectedFavStorePage],

            unfavStoresContext: [unfavStores, setUnfavStores],
            selectedUnfavStorePageContext: [selectedUnfavStorePage, setSelectedUnfavStorePage],

            storeSortByOptionContext: [storeSortByOption, setStoreSortByOption],

            favBrandsContext: [favBrands, setFavBrands],
            selectedFavBrandPageContext: [selectedFavBrandPage, setSelectedFavBrandPage],

            unfavBrandsContext: [unfavBrands, setUnfavBrands],
            selectedUnfavBrandPageContext: [selectedUnfavBrandPage, setSelectedUnfavBrandPage],

            brandSortByOptionContext: [brandSortByOption, setBrandSortByOption],

            storeOffersContext: [storeOffers, setStoreOffers],

            notificationsContext: [notifications, setNotifications],

            productTrackingRulesContext: [productTrackingRules, setProductTrackingRules],

            productsOrientationContext: [productsOrientation, setProductsOrientation],




        }}>
            {props.children}
        </GlobalContext.Provider>
    )
}