import React, { useEffect, useState } from 'react';
import {ASSETS_URL} from '../../Utility/AllMagicStrings';

const FbRedirect = () => {
    const [message, setMessage] = useState('Please wait....');
    useEffect(()=>{
        setTimeout(() => {
            const siteUrl = window.location.href;
            if(siteUrl.includes('error_code')){
                setMessage('Something went wrong')
            }
            else if(siteUrl.includes('access_token')){
                setMessage('Please wait....')
            }
            
        }, 2000);
    },[])
    return (
        <div style={{display:'flex',flexDirection:'column', justifyContent:'center',alignItems:'center',height:'80vh'}}>
            <img style={{height:200,width:200}} src={ASSETS_URL + "chotu.svg"}/>
            <div style={{fontSize:22}}>{message}</div>
        </div>
    )
}

export default FbRedirect;